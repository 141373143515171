/* Themes */
   @media screen {

      .App[data-theme="statista"] {

         --colorscheme-sidebar-back-very:      #0f203b;
         --colorscheme-sidebar-back-quite:     #1f3751;
         --colorscheme-sidebar-back-some:      #266fdf;
         --colorscheme-sidebar-back-bit:       #266fdf;
         --colorscheme-sidebar-front-very:     white;
         --colorscheme-sidebar-front-some:     #c1c1c1;
         --colorscheme-sidebar-front-contrast: #266fdf;
         --colorscheme-sidebar-border:         transparent;
         --colorscheme-sidebar-shadow:         0 0 8px 0 rgba(0,0,0,0.5);

         --colorscheme-chat-back-very:         white;
         --colorscheme-chat-trans-very:        #ffffffaa; /* rgba(32, 33, 35, 0.5) */
         --colorscheme-chat-back-quite:        #3b3c3d;
         --colorscheme-chat-back-some:         #eeeeee;
         --colorscheme-chat-back-slight:       #444654;
         --colorscheme-chat-back-bit:          #4b4c4d;
         --colorscheme-chat-front-very:        #0f203b;
         --colorscheme-chat-front-some:        #266fdf;
         --colorscheme-chat-front-bit:         gray;

         --colorscheme-dialogue-back-very:     white;
         --colorscheme-dialogue-back-some:     #eeeeee;
         --colorscheme-dialogue-back-bit:      #dddddd;
         --colorscheme-dialogue-front-very:    #266fdf;
         --colorscheme-dialogue-front-some:    #0f2741;

         --colorscheme-lighten:                rgba(255, 255, 255, 0.1);
         --colorscheme-darken:                 rgba(0, 0, 0, 0.1);
         --colorscheme-grayen:                 rgba(125, 125, 125, 0.2);
         --colorscheme-backdrop:               rgba(0, 0, 0, 0.33);
         --colorscheme-shadow:                 0 0 8px 0 rgba(0,0,0,0.25);
         --colorscheme-shadow-strong:          0 0 50px 0 rgba(0,0,0,0.33);

         --colorscheme-accent-code:            black;
         --colorscheme-accent-action:          #266fdf;
         --colorscheme-accent-ready:           white;

         --colorscheme-avatar-back-bot:        #0f2741;
         --colorscheme-avatar-front-bot:       white;
         --colorscheme-avatar-back-user:       #266fdf;
         --colorscheme-avatar-front-user:      white;

         --colorscheme-help-background:        #266fdf;
         --colorscheme-help-font-color:        white;
         --colorscheme-help-border-color:      white;

      /* --image-logo-left:                    url('../img/logo_light.png'); */
      /* --image-logo-right:                   url('../img/logo_dark.png');  */

      }

      .App[data-theme="chatgpt"] {

         --colorscheme-sidebar-back-very:      #202123;
         --colorscheme-sidebar-back-quite:     #3b3c3d;
         --colorscheme-sidebar-back-some:      #393a3b;
         --colorscheme-sidebar-back-bit:       #4b4c4d;
         --colorscheme-sidebar-front-very:     white;
         --colorscheme-sidebar-front-some:     #c1c1c1;
         --colorscheme-sidebar-front-contrast: #0da37f;
         --colorscheme-sidebar-border:         transparent;
         --colorscheme-sidebar-shadow:         none;

         --colorscheme-chat-back-very:         #343541;
         --colorscheme-chat-trans-very:        #343541aa; /* rgba(32, 33, 35, 0.5) */
         --colorscheme-chat-back-quite:        #3b3c3d;
         --colorscheme-chat-back-some:         #40414f;
         --colorscheme-chat-back-slight:       #444654;
         --colorscheme-chat-back-bit:          #4b4c4d;
         --colorscheme-chat-front-very:        white;
         --colorscheme-chat-front-some:        silver;
         --colorscheme-chat-front-bit:         gray;

         --colorscheme-dialogue-back-very:     #202123;
         --colorscheme-dialogue-back-some:     #3b3c3d;
         --colorscheme-dialogue-back-bit:      #4b4c4d;
         --colorscheme-dialogue-front-very:    white;
         --colorscheme-dialogue-front-some:    silver;

         --colorscheme-lighten:                rgba(255, 255, 255, 0.1);
         --colorscheme-darkenen:               rgba(0, 0, 0, 0.1);
         --colorscheme-grayen:                 rgba(125, 125, 125, 0.2);
         --colorscheme-backdrop:               rgba(0, 0, 0, 0.33);
         --colorscheme-shadow:                 0 0 8px 0 rgba(0,0,0,0.25);
         --colorscheme-shadow-strong:          0 0 50px 0 rgba(0,0,0,0.33);

         --colorscheme-accent-code:            black;
         --colorscheme-accent-action:          #0da37f;;
         --colorscheme-accent-ready:           lime;

         --colorscheme-avatar-back-bot:        #0da37f;
         --colorscheme-avatar-front-bot:       white;
         --colorscheme-avatar-back-user:       white;
         --colorscheme-avatar-front-user:      #4b4c4d;

      /* --image-logo-left:                    url('../img/logo_light.png'); */
      /* --image-logo-right:                   url('../img/logo_light.png'); */

      }

      .App {
         
         --fontscheme-normal-family:           'Open Sans', Helvetica, Arial, sans-serif;
         --fontscheme-normal-size:             1.1em; /* 1.07em */
         --fontscheme-logo-size:               24px;
         --fontscheme-large-size:              125%;
         --fontscheme-small-size:              0.95em;
         --fontscheme-button-size:             1.1em;
         --fontscheme-help-family:             serif;
         --fontscheme-help-size:               9.6px; /* 60% */
         --fontscheme-code-family:             'Roboto Mono', sans-serif;
         --fontscheme-code-size:               90%;
         --fontscheme-editor-size:             16px;

         --app-background:                     var(--colorscheme-sidebar-back-some);
         --app-font-color:                     var(--colorscheme-sidebar-front-very);
         --app-link-color:                     var(--colorscheme-chat-front-some);
         --app-link-hover:                     var(--colorscheme-chat-front-bit);

         --sidebar-background:                 var(--colorscheme-sidebar-back-very);
         --sidebar-disground:                  var(--colorscheme-sidebar-back-quite);
         --sidebar-dishover:                   var(--colorscheme-sidebar-back-quite);
         --sidebar-foreground:                 var(--colorscheme-sidebar-back-quite);
         --sidebar-forehover:                  var(--colorscheme-sidebar-back-bit);
         --sidebar-border-color:               var(--colorscheme-sidebar-border);
         --sidebar-font-color:                 var(--colorscheme-sidebar-front-very);
         --sidebar-font-color-inactive:        var(--colorscheme-sidebar-front-some);
         --sidebar-link-color:                 var(--colorscheme-sidebar-front-contrast);
         --sidebar-link-hover:                 var(--colorscheme-sidebar-front-some);
         --sidebar-shadow:                     var(--colorscheme-sidebar-shadow);

         --chat-background:                    var(--colorscheme-chat-back-very);

         --message-background:                 var(--colorscheme-chat-back-very);
         --message-fadeout:                    0 0 20px var(--colorscheme-chat-back-very), 0 0 20px var(--colorscheme-chat-back-very), 0 0 20px var(--colorscheme-chat-back-very), 0 0 20px var(--colorscheme-chat-back-very), 0 0 20px var(--colorscheme-chat-back-very), 0 0 15px var(--colorscheme-chat-back-very), 0 0 15px var(--colorscheme-chat-back-very), 0 0 15px var(--colorscheme-chat-back-very), 0 0 10px var(--colorscheme-chat-back-very), 0 0 10px var(--colorscheme-chat-back-very), 0 0 10px var(--colorscheme-chat-back-very), 0 0 10px var(--colorscheme-chat-back-very);
         --message-border:                     var(--colorscheme-chat-back-some);
         --message-bot-background:             var(--colorscheme-chat-back-slight);
         --message-background:                 var(--colorscheme-chat-back-very);
         --message-font-color:                 var(--colorscheme-chat-front-very);
         --message-link-color:                 var(--colorscheme-chat-front-some);
         --message-link-hover:                 var(--colorscheme-chat-front-bit);
         --message-client-background:          var(--colorscheme-chat-back-some);
         --message-client-font-color:          var(--colorscheme-chat-front-some);
         --message-bot-background:             transparent;
         --message-bot-font-color:             inherit;
         --message-bot-avatar-bg:              var(--colorscheme-avatar-back-bot);
         --message-bot-avatar-font:            var(--colorscheme-avatar-front-bot);
         --message-user-background:            transparent;
         --message-user-font-color:            inherit;
         --message-user-avatar-bg:             var(--colorscheme-avatar-back-user);
         --message-user-avatar-font:           var(--colorscheme-avatar-front-user);

         --input-background:                   var(--colorscheme-chat-back-some);
         --input-shadow:                       var(--colorscheme-shadow);
         --input-font-color:                   var(--colorscheme-chat-front-very);

         --editor-background:                  var(--colorscheme-chat-back-very);
         --editor-background-trans:            var(--colorscheme-chat-trans-very);
         --editor-font-color:                  var(--colorscheme-chat-front-very);

         --open-background:                    transparent;
         --open-background-hover:              var(--colorscheme-grayen);
         --open-color:                         var(--colorscheme-chat-front-some);
         --open-color-hover:                   var(--colorscheme-chat-front-very);

         --code-inline-background:             var(--colorscheme-chat-back-some);
         --code-inline-background-hover:       var(--colorscheme-accent-code);
         --code-inline-font-color:             var(--colorscheme-sidebar-front-very);
         --code-inline-font-color-hover:       var(--colorscheme-accent-ready);

         --code-action-background:             var(--colorscheme-chat-back-some);
         --code-action-background-hover:       var(--colorscheme-accent-code);
         --code-action-font-color:             var(--colorscheme-chat-front-very);
         --code-action-font-color-hover:       var(--colorscheme-accent-action);
         --code-action-font-color-disabled:    var(--colorscheme-chat-front-some);
         
         --code-block-background:              var(--colorscheme-sidebar-back-very);
         --code-block-background-hover:        var(--colorscheme-accent-code);
         --code-block-font-color:              var(--colorscheme-sidebar-front-very);
         --code-block-font-color-hover:        var(--colorscheme-accent-ready);

         --control-background:                 var(--colorscheme-sidebar-back-very);
         --control-background-disabled:        var(--colorscheme-chat-front-bit);
         --control-background-hover:           var(--colorscheme-sidebar-back-some);
         --control-font-color:                 var(--colorscheme-sidebar-front-very);
         --control-font-color-hover:           var(--colorscheme-sidebar-front-very);
         --control-font-color-disabled:        var(--colorscheme-sidebar-front-bit);
         --control-shadow:                     var(--colorscheme-shadow);

         --dialogue-backdrop:                  var(--colorscheme-backdrop);
         --dialogue-background:                var(--colorscheme-dialogue-back-very);
         --dialogue-shadow:                    var(--colorscheme-shadow-strong);
         --dialogue-font-color:                var(--colorscheme-dialogue-front-very);
         --dialogue-chrome-background:         var(--colorscheme-dialogue-back-some);
         --dialogue-chrome-background-hover:   var(--colorscheme-dialogue-back-bit);
         --dialogue-chrome-font-color:         var(--colorscheme-dialogue-front-some);
         --dialogue-chrome-font-hover:         var(--colorscheme-dialogue-front-very);

         --help-font-background:               var(--colorscheme-avatar-back-user);
         --help-font-color:                    var(--colorscheme-avatar-front-user);
         --help-sidebar-border-color:          var(--colorscheme-sidebar-front-very);
         --help-chat-border-color:             var(--colorscheme-chat-front-very);
      }

      .App * {
         -webkit-transition: opacity 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out, visibility 0.2s ease-out;
         -moz-transition:    opacity 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out, visibility 0.2s ease-out;
         -o-transition:      opacity 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out, visibility 0.2s ease-out;
         transition:         opacity 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out, visibility 0.2s ease-out;
      }

      .App > .sidebar img[src="img/trans.png"] {
      /* background-image: url(--image-logo-left); */
      }
      .App > div:not(.sidebar) img[src="img/trans.png"] {
      /* background-image: url(--image-logo-right); */
      }
      
   }

/* Layout */
   @media screen {

   /* General */
      @media screen {
         
      /* App */
         .App {
            display: flex;
            position: fixed;
            top: 0px; right: 0px; bottom: 0px; left: 0px;
         }

         .hidden {
            display: none;
         }
      }

   /* Sidebar */
      @media screen {
         
      /* Sidebar */
         .sidebar {
            width: 300px;
            padding: 10px;
            padding-bottom: 25px;
            overflow-x: auto;
         }
         .sidebar h1 {
            margin-bottom: 0px;
            cursor: default;
         }
         .sidebar select,
         .sidebar input,
         .sidebar .reset {
            width: calc(100% - 30px);
            margin-top: 10px; 
            margin-right: 15px; 
            margin-left: 15px;
            padding: 10px;
         }
         .sidebar input[type='text'] {
            padding-top: 12px;
            padding-bottom: 12px;
         }
         .sidebar .reset span {
            padding-left: 0px;
            padding-right: 12px;
         }
         .sidebar > div > div > span {
            margin-top: 20px;
            display: inline-block;
         }
         .sidebar .bottom {
            position: absolute;
            bottom: 25.5px; 
            width: calc(300px - 20px);
         }

         .sidebar > div > div:not(
            .sidebar-header, .functions,
            .models, .temperatures, .tokens, .prompts, .role,
            .aiClient, .humanResources, .officeManagement, .statistaApi
         ),
         .sidebar .theme,
         .sidebar .reset { 
            display: none;
         }
         .sidebar .functions h3,
         .sidebar .functions h4 {
         /* display: none; */
         }

         .sidebar .tokenDisplay,
         .sidebar .showTokens {
            display: none;
         }
         .sidebar .tokenDisplay[data-display='true'],
         .sidebar .showTokens[data-display='true'] {
            display: block;
         }

         .App[data-view="assistants"] .sidebar > .client {
            display: none;
         }
         .App[data-view="assistants"] .sidebar > .assistants {
            display: block;
         }
         .App:not([data-view="assistants"]) .sidebar > .client {
            display: block;
         }
         .App:not([data-view="assistants"]) .sidebar > .assistants {
            display: none;
         }

         .App[data-view="assistants"] .sidebar .assistants .bottom {
            width: calc(300px - 45px);
         }

         .App[data-view="assistants"] .chat-input-holder > .open {
            display: none !important;
         }

      }

   /* Chat */
      @media screen {
         
         .chatbox {
            flex: 1;
            position: relative;
            padding-bottom: 103px;
            height: 100%;
            overflow-x: auto;
         }
         .chat-input-holder {
            padding: 12px;
            position: fixed;
            bottom: 23px;
            right: 0px; left: 300px;
         }
         .chat-input-textarea {
            margin-left: 1px;
            margin-right: 0px;
         }
         .chat-input-submit {
            display: none;
         }

         .growWrap {
            display: grid;
         }
         .growWrap::after {
            content: attr(data-replicated-value) " ";
            white-space: pre-wrap;
            visibility: hidden;
         }
         .growWrap > textarea {
            resize: none;
            overflow: hidden;
            overflow-y: scroll;
         }
         .growWrap > textarea,
         .growWrap::after {
            grid-area: 1 / 1 / 2 / 2;
            width: calc(100vw - 300px - 10px - 40px);
            max-height: calc((100vh - 40px) / 2);
            line-height: 1.5em;
            margin-left: 12px;
            margin-bottom: -10px;
            padding: 9px;
            padding-left: 12px;
            padding-right: 40px;
         }

         .chat-log {
         }

      }

   /* Message */
      @media screen {
         
         .chat-log > div {
            padding: 20px;
            padding-bottom: 14px;
         }
         .chat-log > div.client {
            padding: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
         }
         .chat-log > div:first-child {
            padding-top: 60px;
         }
         .chat-log > div.client:not(:last-child) {
            margin-bottom: -3px;
         }
         .chat-log > div.client + .chat-log > div:not(.client) {
            margin-top: 16px;
         }
         .chat-log > div > div {
            position: relative;
            display: -webkit-box;
            max-width: 640px;
            margin-right: auto;
            margin-left: auto;
            padding: 12px;
            padding-right: 24px;
         }
         .chat-log > div .avatar {
            width: 40px;
            height: 40px;
            padding-top: 10px;
         }
         .chat-log > div.client .avatar {
            display: none;
         }
         .chat-log > div .message {
            margin-top: -15px;
            margin-bottom: -15px;
            padding-right: 20px;
            padding-left: 20px;
         }
         .chat-log > div.client .message {
            display: inline-block;
            width: calc(100% - 0px);
         }

         .chat-log > .waiting {
            display: none;
         }

         .App:not([data-prompts-loaded="true"]) .chat-log > .bot:last-child code.button,
         .App:not([data-prompts-loaded="true"]) .prompteneer button#save,
         .App:not([data-prompts-loaded="true"]) #promptGroup select,
         .App:not([data-prompts-loaded="true"]) .promptSavingConfirm {
            cursor: wait;
         }
         .App[data-prompts-loaded="true"] .chat-log > .bot:last-child code.button,
         .App[data-prompts-loaded="true"] .prompteneer button#save,
         .App[data-prompts-loaded="true"] #promptGroup select,
         .App[data-prompts-loaded="true"] .promptSavingConfirm {
            cursor: pointer;
         }

         .App:not([data-prompts-loaded="true"]) .chat-log > .bot:last-child code.button {
            background-color: var(--code-action-background);
            color: var(--app-link-hover);
         }
         .App[data-prompts-loaded="true"] .chat-log > .bot:last-child code.button {
            
         }

         .App:not([data-prompts-loaded="true"]) .prompteneer button#save,
         .App:not([data-prompts-loaded="true"]) .promptSavingConfirm {
            background-color: var(--control-background-disabled) !important;
            color: var(--control-font-color-disabled) !important;
         }
         .App[data-prompts-loaded="true"] .prompteneer button#save,
         .App[data-prompts-loaded="true"] .promptSavingConfirm {
            
         }

         .App:not([data-prompts-loaded="true"]) #promptGroup select {
            background-color: var(--dialogue-chrome-background);
         }
         .App:not([data-prompts-loaded="true"]) #promptGroup:hover select {
            background-color: var(--dialogue-chrome-background) !important;
            color: red !important;
         }
         .App[data-prompts-loaded="true"] #promptGroup select {
            
         }

      }

   /* Feedback */
      @media screen {

         .chat-log > div .feedback {
            display: none;
            position: absolute;
            top: 17px;
            right: 0px;
            z-index: 1;
         }
         .chat-log > .response .feedback {
            display: none;
         }
         .chat-log > .bot:not(:nth-child(1)):not(:nth-child(2)):not(.waiting):not(.waited):hover .feedback,
         .chat-log > .response:hover .feedback, 
         .feedback[data-choice="true"], 
         .feedback[data-choice="false"] {
            display: block;
         }
         .chat-log > div .feedback i {
            padding: 10px 5px 10px 5px;
            margin: -5px 0px -5px 0px;
         }
         .chat-log > div .feedback h3,
         .chat-log > div .feedback span.cta,
         .chat-log > div .feedback button,
         .chat-log > div .feedback textarea {
            display: none;
         }
         .chat-log > div .feedback:not([data-thanks="true"]):not([data-choice="null"]):hover h3,
         .chat-log > div .feedback:not([data-thanks="true"]):not([data-choice="null"]):hover span.cta,
         .chat-log > div .feedback:not([data-thanks="true"]):not([data-choice="null"]):hover button,
         .chat-log > div .feedback:not([data-thanks="true"]):not([data-choice="null"]):hover textarea,
         .chat-log > div .feedback:not([data-thanks="true"])[data-open="true"]:hover h3,
         .chat-log > div .feedback:not([data-thanks="true"])[data-open="true"]:hover span.cta,
         .chat-log > div .feedback:not([data-thanks="true"])[data-open="true"]:hover button,
         .chat-log > div .feedback:not([data-thanks="true"])[data-open="true"]:hover textarea {
            display: inline-block;
         }

      }

   /* Editor */
      @media screen {
         
         .prompteneer {
            display: none;
            position: fixed;
            top: 0px; right: 0px; bottom: 100px; left: 300px;
            width: calc(100vw - 300px);
            z-index: 4;
         }

         .prompteneer .lists {
            display: none;
            position: absolute;
            top: 0px; bottom: -100px; left: -260px;
            width: 260px;
         }

         .chat-input-holder .open,
         .prompteneer .close {
            position: absolute;
            right: 25px; 
            width: 44px;
            height: 44px;
            text-align: center;
            vertical-align: middle;
         }
         .chat-input-holder .open {
            bottom: 2px;
            padding-top: 11px;
         }
         .prompteneer .close {
            bottom: -75px;
            padding-top: 7px;
         }

         .prompteneer .editor {
            width: calc(100%);
            height: calc(100%);
            padding: 25px;
            padding-top: 25px;
            padding-bottom: 0px;
            border-width: 0px;
         }

         .prompteneer .controls {
            position: absolute;
            right: 0px; bottom: -100px; left: 0px;
            height: 100px;
            padding-top: 33px;
            padding-left: 25px;
         }
         .prompteneer .controls button {
            height: 44px;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 25px;
            margin-top: -2px;
         }
         .prompteneer .controls #new {
            display: none;
         }

      }

   /* Saver */
      @media screen {
         
         .promptSaving {
            display: none;
            position: fixed;
            top: 0px; right: 0px; bottom: 0px; left: 300px;
            padding-left: 0px;
         }
         .promptSavingDialogue {
            position: fixed;
         /* top: 100px; right: 100px; bottom: 100px; left: 360px; */
            top:  calc(50% - ((575px - 0px) / 2));
            left: calc(50% - ((500px - 300px) / 2));
            width: 500px;
            height: 525px;
         }
         .promptSavingHeader {
            position: absolute;
            top: 0px; right: 0px; left: 0px;
            height: 60px;
            padding: 20px;
         }
         .promptSavingHeader div {
            position: absolute;
            top: 12px; right: 6px;
            width: 40px;
            height: 40px;
            padding-top: 5px;
            font-size: var(--fontscheme-large-size);
            background-color: transparent;
            cursor: pointer;
            font-weight: normal;
         }
         .promptSavingForm {
            position: absolute;
            top: 60px; right: 0px; bottom: 80px; left: 0px;
            padding-top: 20px;
            padding-right: 20px;
            padding-bottom: 40px;
            padding-left: 20px;
            overflow-x: auto;
         }
         .promptSavingForm div {
            padding-top: 5px;
            padding-right: 20px;
            padding-bottom: 5px;
            padding-left: 20px;
         }
         .promptSavingForm div input,
         .promptSavingForm div textarea,
         .promptSavingForm div select {
            width: calc(100% - 20px);
            margin: 10px;
            border-width: 0px;
            border-radius: 5px;
         }
         .promptSavingForm div input {
            padding: 10px;
            height: 40px;
         }
         .promptSavingForm div select {
            padding: 10px;
            height: 40px;
         }
         .promptSavingForm div textarea {
            padding: 10px;
            height: 170px;
            resize: none;
         }
         .promptSavingForm .notice {
            display: none;
            font-style: italic; 
         }
         .promptSavingForm:not([data-group_MEliSuifmadU]) #promptStatisticId,
         .promptSavingForm:not([data-group_BieCfuhAvOAI]) #promptStatisticQuery,
         .promptSavingForm:not([data-group_MEliSuifmadU]) #promptPrecision,
         .promptSavingForm:not([data-group_BieCfuhAvOAI]) #promptPrecision {
            display: none;
         }
         .promptSavingForm:not([data-group_MEliSuifmadU]):not([data-group_BieCfuhAvOAI]) .section.api {
            display: none;
         }

         .promptSavingFooter {
            position: absolute;
            right: 0px; bottom: 0px; left: 0px;
            height: 80px;
            padding-top: 20px;
         }
         .promptSavingFooter button {
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: var(--fontscheme-button-size);
            cursor: pointer;
            padding: 10px;
            padding-left: 15px;
            padding-right: 15px;
         }
         .promptSavingFooter button:not(:first-child) {
            margin-left: 20px;
         }

         .promptSavingOverlay {
            position: absolute;
         /* top: 100px; right: 100px; bottom: 100px; left: 360px; */
            top:  59px;
            right: 0px;
            bottom: 80px;
            left: 0px;
         }
         .promptSavingDialogue:not([data-overwrite="true"]) .promptSavingOverlay {
            display: none;
         }

      }

   /* Roles */
      @media screen {

         .App[data-role=""] .roleManager:not(.roleUser),
         .App[data-role=""] .roleAngel:not(.roleUser),
         .App[data-role=""] .roleAdmin:not(.roleUser) { display: none !important; }

         .App[data-role="user"] .roleManager:not(.roleUser),
         .App[data-role="user"] .roleAngel:not(.roleUser),
         .App[data-role="user"] .roleAdmin:not(.roleUser) { display: none !important; }

         .App[data-role="manager"] .roleUser:not(.roleManager),
         .App[data-role="manager"] .roleAngel:not(.roleManager),
         .App[data-role="manager"] .roleAdmin:not(.roleManager) { display: none !important; }

         .App[data-role="angel"] .roleUser:not(.roleAngel),
         .App[data-role="angel"] .roleManager:not(.roleAngel),
         .App[data-role="angel"] .roleAdmin:not(.roleAngel) { display: none !important; }

         .App[data-role="admin"] .roleUser:not(.roleAdmin),
         .App[data-role="admin"] .roleManager:not(.roleAdmin),
         .App[data-role="admin"] .roleAngel:not(.roleAdmin) { display: none !important; }

         .App[data-role="user"]      .sidebar          .chooseRole option[value="angel"],
         .App[data-role="user"]      .promptSavingForm #promptRole option[value="angel"],
         .App[data-role="manager"]   .sidebar          .chooseRole option[value="angel"],
         .App[data-role="manager"]   .promptSavingForm #promptRole option[value="angel"],

         .App[data-role="user"]      .sidebar          .chooseRole option[value="admin"],
         .App[data-role="user"]      .promptSavingForm #promptRole option[value="admin"],
         .App[data-role="manager"]   .sidebar          .chooseRole option[value="admin"],
         .App[data-role="manager"]   .promptSavingForm #promptRole option[value="admin"],
         .App[data-role="angel"] .sidebar              .chooseRole option[value="admin"],
         .App[data-role="angel"] .promptSavingForm #promptRole     option[value="admin"] {
            display: none;
         }

         .App[data-highest=""] .highestManager:not(.highestUser),
         .App[data-highest=""] .highestAngel:not(.highestUser),
         .App[data-highest=""] .highestAdmin:not(.highestUser) { display: none !important; }

         .App[data-highest="user"] .highestManager:not(.highestUser),
         .App[data-highest="user"] .highestAngel:not(.highestUser),
         .App[data-highest="user"] .highestAdmin:not(.highestUser) { display: none !important; }

         .App[data-highest="manager"] .highestUser:not(.highestManager),
         .App[data-highest="manager"] .highestAngel:not(.highestManager),
         .App[data-highest="manager"] .highestAdmin:not(.highestManager) { display: none !important; }

         .App[data-highest="angel"] .highestUser:not(.highestAngel),
         .App[data-highest="angel"] .highestManager:not(.highestAngel),
         .App[data-highest="angel"] .highestAdmin:not(.highestAngel) { display: none !important; }

         .App[data-highest="admin"] .highestUser:not(.highestAdmin),
         .App[data-highest="admin"] .highestManager:not(.highestAdmin),
         .App[data-highest="admin"] .highestAngel:not(.highestAdmin) { display: none !important; }

      }

   /* Entrance, loggedOut */
      @media screen {
         
         .App[data-policy-confirmed="true"] .entrance {
            display: none !important;
         }
         .entrance,
         .loggedOut {
            position: fixed;
            z-index: 9;
            top: 0px; right: 0px; bottom: 0px; left: 0px;
            vertical-align: middle;
         }
         .entrance.inside,
         .loggedOut.inside {
            left: 300px;
         }
         .entrance  table, 
         .entrance  tr, 
         .entrance  td,
         .loggedOut table, 
         .loggedOut tr, 
         .loggedOut td {
            width: 100%;
            height: 100%;
            border: 0;
         }
         .entrance  td > div,
         .loggedOut td > div {
            width: 100%;
            max-width: 600px;
            margin: auto;
         }
         .entrance  h1,
         .loggedOut h1 {
            
         }
         .entrance  p,
         .loggedOut p {
            
         }
         .entrance  a,
         .loggedOut a {
            
         }
         .entrance  a:hover,
         .loggedOut a:hover {
            
         }
         .entrance  input[type="checkbox"],
         .loggedOut input[type="checkbox"] {
            
         }
         .entrance  button,
         .loggedOut button {
            height: 44px;
            padding-left: 15px;
            padding-right: 15px;
         }

         .entrance  .regulations,
         .loggedOut .regulations {
            position: absolute;
            bottom: 25px;
            left: 22px;
            text-align: left;
         }
         .entrance  .regulations *,
         .loggedOut .regulations * {
            display: block;
            margin-top: 10px;
         }

      }

   /* LoggedOut (temp) */
      @media screen {
         
         .App[data-view="assistants"] .loggedOut {
         /* display: block; */
         }
         .App:not([data-view="assistants"]) .loggedOut {
         /* display: none; */
         }

      }

   /* Settings, Trouble, Share */
      @media screen {
         
         .settings, .trouble, .share, .loggedOut {
            display: none;
            position: fixed;
            z-index: 8;
            top: 0px; right: 0px; bottom: 0px; left: 300px;
            vertical-align: middle;
            overflow-y: auto;
            padding-top: 10px;
            padding-bottom: 20px;
         }
         .loggedOut {
            background-color: transparent;
            z-index: 99;
            left: 0px;
         }
         .loggedOutScreen {
            position: fixed;
            top: 0px; right: 0px; bottom: 0px; left: 0px;
         }
         .settings table, .trouble table, .share table, 
         .settings tr,    .trouble tr,    .share tr
         .settings td,    .trouble td,    .share td {
            width: 100%;
            height: 100%;
            border: 0;
         }
         .settings  div:not(.settingsClose),
         .trouble   div:not(.troubleClose),
         .share     div:not(.shareClose),
         .loggedOut div:not(.loggedOutClose):not(.loggedOutScreen) {
            width: 100%;
            max-width: 600px;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
         }

         .loggedOut table, .loggedOutScreen {
            position: fixed;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            z-index: 1;
         }
         .loggedOutScreen {
            background-color: var(--sidebar-background);
            z-index: 0;
         }
         .loggedOutClose {
            z-index: 1;
         }

         .settings h1, .trouble h1, .share h1 {
            margin-bottom: 30px;
         }
         .settings h3, .trouble h3, .share h3,
         .settings h4, .trouble h4, .share h4,
         .settings i,  .trouble i,  .share i {
            display: block;
         }
         .settings h4, .trouble h4, .share h4 {
            margin-bottom: 30px;
            margin-top: 0px;
         }
         .settings ul, .trouble ul, .share ul {
            width: 100%;
            max-width: 300px;
            padding-left: 0px;
            text-align: left;
         }
         .settings ul li, .trouble ul li, .share ul li {
            list-style: none;
            cursor: default;
            margin-top: -5px;
         }
         .settings .userProfile .email, .trouble .userProfile .email, .share .userProfile .email,
         .settings .userProfile .dept,  .trouble .userProfile .dept,  .share .userProfile .dept {
            margin-top: 2px;
         }
         .settings div input,    .trouble div input,    .share div input,
         .settings div textarea, .trouble div textarea, .share div textarea,
         .settings div select,   .trouble div select,   .share div select {
            width: calc(100% - 20px);
            max-width: calc(300px - 20px);
            margin: 10px;
            border-width: 0px;
            border-radius: 5px;
         }
         .settings div input, .trouble div input, .share div input {
            padding: 10px;
            height: 40px;
         }
         .settings input[type="checkbox"], .trouble input[type="checkbox"], .share input[type="checkbox"] {
            
         }
         .settings div select, .trouble div select, .share div select {
            padding: 10px;
            height: 40px;
         }
         .settings button, .trouble button, .share button {
            height: 44px;
            padding-left: 15px;
            padding-right: 15px;
         /* width: calc(100% - 20px); */
            max-width: 280px;
         }

         .settings .footer, .trouble .footer, .share .footer {
            position: absolute;
            bottom: 20px;
            left: 20px;
            text-align: left;
         }
         .settings .footer *, .trouble .footer *, .share .footer * {
            display: block;
            margin-top: 10px;
         }

         .settings .settingsClose, .trouble .troubleClose, .share .shareClose, .loggedOut .loggedOutClose {
            position: fixed;
            top: 20px;
            right: 20px;
            font-size: var(--fontscheme-logo-size);
            color: var(--colorscheme-dialogue-front-some);
            font-weight: bold;
            cursor: pointer;
            padding: 10px;
         }
         .settings .settingsClose:hover, .trouble .troubleClose:hover, .share .shareClose:hover, .loggedOut .loggedOutClose:hover {
            color: var(--colorscheme-dialogue-front-very);
         }
         .loggedOut .loggedOutClose {
            color: var(--colorscheme-sidebar-front-very);
         }
         .loggedOut .loggedOutClose:hover {
            color: var(--colorscheme-sidebar-front-some);
         }

         .settings .userProfile {
            padding-left: 11px;
            margin-bottom: 30px;
         }
         .settings .userProfile li label {
            display: inline-block;
            width: 33%;
            min-width: 100px;
         }
         .settings .userProfile li span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(66% - 10px);
            margin-top: 4px;
            margin-bottom: -4px;
         }
         .settings .userProfile li:not(:first-child) {
            margin-top: 10px;
         }

         .settingsPeople:not(.visible) {
         /* display: none; */
         }

         .share input[type="checkbox"] {
            
         }
         .share input[type="radio"] {
            width: 16px;
            height: 16px;
            margin-right: 5px;
         }
         .share input[type="text"] {
            
         }
         .share input[type="radio"] {
            
         }

      }

   /* SignIn */
      @media screen {
         
         .signIn {
            display: none;
            position: fixed;
            z-index: 10;
            top: 0px; right: 0px; bottom: 0px; left: 0px;
            vertical-align: middle;
            overflow-y: auto;
         }
         .signIn.signedOut {
            display: block;
         }
         .signIn table, 
         .signIn tr, 
         .signIn td {
            width: 100%;
            height: 100%;
            border: 0;
         }
         .signIn div:not(.signInClose) {
            width: 100%;
            max-width: 600px;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
         }
         .signIn h1 {
            
         }
         .signIn h3,
         .signIn i {
            display: block;
         }
         .signIn ul {
            width: 100%;
            max-width: 300px;
            padding-left: 0px;
            text-align: left;
         }
         .signIn ul li {
            list-style: none;
            cursor: default;
         }
         .signIn button {
            height: 44px;
            margin-top: 5px;
            padding-left: 15px;
            padding-right: 15px;
         }

         .signIn .signInClose {
            position: fixed;
            top: 20px;
            right: 20px;
            font-size: var(--fontscheme-logo-size);
            color: var(--colorscheme-dialogue-front-some);
            font-weight: bold;
            cursor: pointer;
            padding: 10px;
         }
         .signIn .signInClose:hover {
            color: var(--colorscheme-dialogue-front-very);
         }

      }

   /* Mobile */
      @media screen {

         .sidebar-replacement {
            display: none;
         }

      }
      @media screen and (max-width: 599px) {

         .sidebar-replacement {
            display: block;
            position: fixed;
            top: 8px;
            right: 3px;
            z-index: 3;
            font-size: 35px;
            color: silver;
            padding-top: 0px;
            padding: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;
            opacity: 0.5;
         }
         .sidebar-replacement:hover {
            opacity: 1;
         }
         .App:not(.extended) .sidebar-replacement .opened { display: none;  }
         .App:not(.extended) .sidebar-replacement .closed { display: block; }
         .App.extended       .sidebar-replacement .opened { display: block; }
         .App.extended       .sidebar-replacement .closed { display: none;  }
         .App:not(.extended) .sidebar-replacement {
            display: block;
         }

         .App:not(.extended) .sidebar {
            display: none;
         }
         .App.extended .sidebar {
            display: block;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            width: 100%;
            z-index: 2;
         }
         .App.extended .chatbox {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 1;
            padding-top: 12px;
         }
         .App .chat-input-holder {
            left: 0px;
         }
         .App .chat-input-textarea {
            width: calc(100vw - 0px - 10px - 40px);
         }
         .prompteneer {
            left: 0px;
            width: calc(100vw - 0px);
         }
         .promptSaving {
            left: 0px;
         }
         .promptSavingDialogue {
            left: 20px;
            right: 20px;
            width: calc(100% - 40px);
         }
         .promptSavingOverlay {
            
         }
         .settings, .trouble, .share, .loggedOutScreen {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
         }
         .entrance.inside,
         .loggedOut.inside {
            left: 0px;
         }

      }

   /* Widget */
      @media screen {

         .App[data-view="widget"] .sidebar-replacement {
            display: block;
            position: fixed;
            top: 8px;
            right: 3px;
            z-index: 3;
            font-size: 35px;
            color: silver;
            padding-top: 0px;
            padding: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;
            opacity: 0.5;
         }
         .App[data-view="widget"] .sidebar-replacement:hover {
            opacity: 1;
         }
         .App[data-view="widget"]:not(.extended) .sidebar-replacement .opened { display: none;  }
         .App[data-view="widget"]:not(.extended) .sidebar-replacement .closed { display: block; }
         .App[data-view="widget"].extended       .sidebar-replacement .opened { display: block; }
         .App[data-view="widget"].extended       .sidebar-replacement .closed { display: none;  }
         .App[data-view="widget"]:not(.extended) .sidebar-replacement {
            display: block;
         }
         .App[data-view="widget"] .sidebar-replacement .opened { margin-top: -3px; }

         .App[data-view="widget"]:not(.extended) .sidebar {
            display: none;
         }
         .App[data-view="widget"].extended .sidebar {
            display: block;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            width: 100%;
            z-index: 2;
         }
         .App[data-view="widget"].extended .chatbox {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 1;
            padding-top: 12px;
         }
         .App[data-view="widget"] .chat-input-holder {
            left: 0px;
         }
         .App[data-view="widget"] .chat-input-textarea {
            width: calc(100vw - 0px - 10px - 40px);
         }
         .App[data-view="widget"] .prompteneer {
            left: 0px;
            width: calc(100vw - 0px);
         }
         .App[data-view="widget"] .promptSaving {
            left: 0px;
         }
         .App[data-view="widget"] .promptSavingDialogue {
            left: 20px;
            right: 20px;
            width: calc(100% - 40px);
         }
         .App[data-view="widget"] .promptSavingOverlay {
            
         }
         .App[data-view="widget"] .settings, 
         .App[data-view="widget"] .trouble, 
         .App[data-view="widget"] .share {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
         }
         .App[data-view="widget"] .entrance.inside,
         .App[data-view="widget"] .loggedOut.inside {
            left: 0px;
         }

         .App[data-view="widget"] .chat-input-holder .open,
         .App[data-view="widget"] .chat-log > .bot:first-child ~ .client {
            display: none;
         }

         .App[data-view="widget"] .sidebar > div,
         .App[data-view="widget"] .sidebar > div > div {
            width: 100%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            top: 0px;
         }
         .App[data-view="widget"] .sidebar > div {
            height: 100%;
         }

         .App[data-view="widget"] .sidebar .functions.bottom {
            position: absolute;
            top: calc(100% - 125px);
            left: calc((100% - 400px) / 2);
            margin-left: 0px;
         }
         @media screen and (max-height: 640px) {
            .App[data-view="widget"] .sidebar .functions.bottom {
               position: relative !important;
               top: 0px !important;
            }
         }

      }

   /* Placeholders */
      @media screen {
         .App > .placeholders {
            display: none;
         }
      }

   }

/* Design */
   @media screen {

   /* General */
      @media screen {
         
      /* App */
         .App {
            font-family: var(--fontscheme-normal-family);
            text-align: center;
         }

      }

   /* Sidebar */
      @media screen {
         
         .sidebar h1 {
            font-size: var(--fontscheme-logo-size);
            font-weight: normal;
         }
         .sidebar h1 img {
            width: calc(var(--fontscheme-logo-size) - 6px);
            height: calc(var(--fontscheme-logo-size) - 6px);
            border-radius: 2px;
            margin-right: 8px;
         /* background-image: var(--image-logo-file); */
         }
         .sidebar select,
         .sidebar input,
         .sidebar .reset {
            border-radius: 5px;
            border-width: 1px;
            border-style: solid;
            text-align: left;
         }
         .sidebar select {
            cursor: pointer;
         }
         .sidebar select:disabled {
            cursor: default;
         }
         .sidebar .reset {
            cursor: pointer;
         }
         .sidebar > div > div > span {
            text-align: left;
         }

         .sidebar .toggleSettings input,
         .sidebar .toggleTrouble  input,
         .sidebar .toggleShare    input {
            cursor: pointer;
         }

         .sidebar select option.italic {
            font-style: italic;
         }

      }

   /* Sidebar: Assistants */
      @media screen {
         
         .sidebar > .assistants > .sidebar-header {
            padding-bottom: 15px;
         }
         .sidebar > .assistants > :not(.sidebar-header) {
            margin: 12px;
            margin-top: 10px;
            margin-bottom: 0px;
            padding: 11px;
            border-radius: 5px;
            border-width: 3px;
            border-style: solid;
            text-align: left;
            cursor: pointer !important;
         }
         .sidebar > .assistants > div > strong {
            font-weight: 900;
         }
         .sidebar > .assistants > div > :not(strong) {
            font-weight: normal;
         }

         .sidebar > .assistants > div > span {
            display: block;
            margin-top: 10px;
         }
         .sidebar > .assistants .red,
         .sidebar > .assistants .green,
         .sidebar > .assistants .gray {
            display: none;
            float: right;
            border-radius: 3px;
            border-width: 1px;
            border-style: solid;
            margin-top: -1px;
            margin-right: -3px;
            font-size: 80%;
            padding: 2px;
         }
         .sidebar > .assistants > div:hover .red,
         .sidebar > .assistants > div:hover .green,
         .sidebar > .assistants > div:hover .gray {
            display: inline-block;
         }
         .App[data-theme="statista"] .sidebar > .assistants .red {
            color: red;
            border-color: red;
            background-color: #ffeeee;
         }
         .App[data-theme="statista"] .sidebar > .assistants .green {
            color: green;
            border-color: green;
            background-color: #eeffee;
         }
         .App[data-theme="statista"] .sidebar > .assistants .gray {
            color: gray;
            border-color: gray;
            background-color: #eeeeee;
         }
         .App[data-theme="chatgpt"] .sidebar > .assistants .red {
            color: fuchsia;
            border-color: fuchsia;
            background-color: #330000;
         }
         .App[data-theme="chatgpt"] .sidebar > .assistants .green {
            color: lime;
            border-color: lime;
            background-color: #003300;
         }
         .App[data-theme="chatgpt"] .sidebar > .assistants .gray {
            color: white;
            border-color: white;
            background-color: #333333;
         }
      }

   /* Help */
      @media screen {
         
         h3, h4 {
            display: inline-block;
            margin: 0px;
            font-size: inherit;
            font-weight: normal;
            padding-left: 3px;
         }
         .sidebar h3,
         .sidebar h4 {
            margin-top: 20px;
            cursor: default;
         }
         .sidebar h4 {
            margin-top: 0px;
            margin-bottom: 25px;
         }
         i[title] {
            border: transparent 1px solid;
            border-radius: 50%;
            margin-top: -2px;
            margin-left: 5px;
            padding: 0px;
            padding-top: 1px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 1px;
            font-family: var(--fontscheme-help-family);
            font-size: var(--fontscheme-help-size);
            cursor: context-menu; /* help */
            position: absolute;
            visibility: hidden;
         }
         .App[data-role="admin"] i[title] {
            display: none !important;
         }
         div:hover > h3 i[title],
         div:hover > h4 i[title] {
            border: transparent 1px solid;
            visibility: visible;
         }
         .promptSavingFooter > div {
            position: relative;
            display: inline-block;
         }
         .promptSavingFooter > div:not(:first-child) {
            margin-left: 20px;
         }
         .promptSavingFooter > div i[title] {
            top: -6px;
            right: -6px;
         }
         .promptSavingFooter > div:hover i[title] {
            visibility: visible;
         }
         .open i[title],
         .close i[title] {
            font-size: var(--fontscheme-help-size);
            top: -6px;
            right: -5px;
         }
         .open:hover i[title],
         .close:hover i[title] {
            visibility: visible;
         }
         .controls > div {
            display: inline-block;
            position: relative;
         }
         .controls > div button {
            margin-right: 0px !important;
         }
         .controls > div:not(:first-child):not(:nth-child(2)) {
            margin-left: 20px;
         }
         .controls > div i[title] {
            font-size: var(--fontscheme-help-size);
            top: -7px;
            right: -6px;
         }
         .controls > div:hover i[title] {
            visibility: visible;
         }
         .controls > div #new + i[title] {
            display: none;
         }
         .settings h3 i[title], .trouble i[title], .share i[title],
         .settings h4 i[title], .trouble i[title], .share i[title] {
            display: inline-block;
            margin-top: -3px;
            margin-left: 2px;
            cursor: context-menu;
         }

      }

   /* Chat */
      @media screen {
         
         .growWrap > textarea,
         .growWrap::after {
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: var(--fontscheme-button-size);
            resize: none;
         }

         .chat-input-textarea:disabled {
            cursor: wait;
         }

      }

   /* Message */
      @media screen {
         
         .chat-log > div {
            border-width: 1px;
            border-style: solid;
         }
         .chat-log > div:not(.client) > div .message {
            text-align: left;
            font-size: var(--fontscheme-normal-size);
         }
         .chat-log > div .avatar {
            margin-top: -6px;
            border-radius: 50%;
            text-align: center;
            cursor: default;
            padding-right: 1px;
         }
         .chat-log > div .message {
         /* white-space: break-spaces; */
         }
         .chat-log > div.client {
            text-align: center;
         }
         .chat-log > div.client .message {
            font-style: italic;
            text-align: center;
            font-size: var(--fontscheme-small-size);
         }
         
         .chat-log > div .message p, 
         .chat-log > div .message hr, 
         .chat-log > div .message ul, 
         .chat-log > div .message ol, 
         .chat-log > div .message table, 
         .chat-log > div .message pre,
         .chat-log > div .message h1, 
         .chat-log > div .message h2, 
         .chat-log > div .message h3, 
         .chat-log > div .message h4, 
         .chat-log > div .message h5, 
         .chat-log > div .message h6 {
            margin-top: 15px;
            margin-bottom: 15px;
            white-space: pre-line;
         }
         .chat-log > div .message p {
            line-height: 1.5em;
         }
         .chat-log > div .message span {
            
         }
         .chat-log > div .message ol, 
         .chat-log > div .message ul {
            margin-left: 2px;
            padding: 0px;
            padding-left: 15px;
            white-space: pre-line;
         }
         .chat-log > div .message li {
            padding-left: 5px;
            margin-top: 7.5px;
            margin-bottom: 7.5px;
            line-height: 1.3;
         }
         .chat-log > div .message code {
            margin: -3px;
            margin-left: -1px;
            margin-right: -1px;
            padding: 3px;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 5px;
            color: inherit;
            font-family: var(--fontscheme-code-family);
            font-size: var(--fontscheme-code-size);
         }
         .chat-log > div .message pre {
            display: block;
            width: 100%;
            max-width: 640px;
         }
         .chat-log > div .message pre > code {
            display: block;
            width: 100%;
            max-width: 560px;
            margin: 0px;
            margin-left: 0px;
            margin-right: 0px;
            padding: 10px;
            padding-top: 8px;
            overflow-x: scroll;
            overflow-y: scroll;
            max-height: 50%;
            white-space: pre-wrap;
         }
         .chat-log > div .message pre > code.wrap {
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
         }
         .chat-log > div .message code.button {
            font-family: inherit;
            font-size: inherit;
            cursor: pointer;
         }
         .chat-log > div .message blockquote {
            border-left: transparent 3px solid;
            margin-left: 0px;
            padding: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
         }
         .chat-log > div .message strong {
            font-weight: bold;
         }
         .chat-log > div .message i,
         .chat-log > div .message em {
            font-style: italic;
         }
         .chat-log > div .message s {
            text-decoration: line-through;
         }
         .chat-log > div .message u {
            text-decoration: underline;
         }
         .chat-log > div .message a {
            text-decoration: underline;
         }
         .chat-log > div .message table {
            border-top: transparent 1px solid;
            border-left: transparent 1px solid;
         }
         .chat-log > div .message tr {
            
         }
         .chat-log > div .message td,
         .chat-log > div .message th {
            border-right: transparent 1px solid;
            border-bottom: transparent 1px solid;
            padding: 5px;
         }
         .chat-log > div .message th {
            font-weight: normal;
         }
         .chat-log > div .message h1, 
         .chat-log > div .message h2, 
         .chat-log > div .message h3, 
         .chat-log > div .message h4, 
         .chat-log > div .message h5, 
         .chat-log > div .message h6 {
            display: block;
            padding-left: 0px;
            font-weight: normal;
         }
         .chat-log > div .message h1 {
            font-size: calc(var(--fontscheme-button-size) * 1);
         }
         .chat-log > div .message h2 {
            font-size: calc(var(--fontscheme-button-size) * 0.98);
         }
         .chat-log > div .message h3 {
            font-size: calc(var(--fontscheme-button-size) * 0.96);
         }
         .chat-log > div .message h4 {
            font-size: calc(var(--fontscheme-button-size) * 0.94);
         }
         .chat-log > div .message h5 {
            font-size: calc(var(--fontscheme-button-size) * 0.92);
         }
         .chat-log > div .message h6 {
            font-size: calc(var(--fontscheme-button-size) * 0.9);
         }

         .chat-log .bot.init .message p > strong,
         .chat-log .bot.init .message p > em {
            border: silver 1px solid;
            padding: 8px;
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;
            font-size: 0.9rem;
            width: 100%;
         }
         .chat-log .bot.init .message p > strong {
            color: red;
            border-color: #ffcccc;
            background: rgba(255,125,125,0.1);
            font-weight: bold;
            text-align: center;
            padding: 16px;
         }
         .chat-log .bot.init .message p > em {
            color: var(--message-font-color);
            border-color: silver;
            background: rgba(125,125,125,0.1);
         }

      }

   /* Feedback */ 
      @media screen {

         .chat-log > div .feedback {
            margin: -10px;
            margin-top: -16px;
            max-width: 350px;
            border-radius: 8px;
            text-align: right;
         }
         .chat-log > div .feedback:not([data-choice="null"]):hover,
         .chat-log > div .feedback[data-open="true"] {
            background-color: white;
            border: black 0px solid;
         }
         .chat-log > div .feedback h3 {
            position: absolute;
            top: 21px;
            left: 15px;
            width: calc(100% - 80px);
            height: 20px;
            margin-top: -5px;
            padding-left: 0px;
            cursor: default;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
         }
         .chat-log > div .feedback i {
            cursor: pointer;
            position: absolute;
         }
         .chat-log > div .feedback i.pos {
            top: 12px;
            right: 36px;
         }
         .chat-log > div .feedback i.neg {
            top: 15px;
            right: 9px;
         }
         .chat-log > div .feedback i:hover {
            
         }
         .chat-log > div .feedback textarea {
            border: 0;
            height: 25%;
            margin-bottom: 9px !important;
            margin-top: 22px !important;
            max-height: 100px;
            min-height: 70px;
            min-width: 225px;
            outline: none;
            padding: 5px;
            resize: none;
            width: calc(100% - 20px);
            margin: 10px;
         }
         .chat-log > div .feedback button {
            margin-bottom: 15px;
            margin-right: 15px;
            cursor: pointer;
         }
         .chat-log > div .feedback button:disabled {
            cursor: default;
         }
         .chat-log > div .feedback span.cta {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: calc(100% - 70px);
            margin-bottom: 10px;
            margin-left: 5px;
            padding: 10px;
            text-align: left;
            color: silver;
            cursor: default;
            font-size: 85%;
         }

         .chat-log > div .feedback[data-thanks="true"] .thanks {
            display: block;
            padding-top: 15px;
            padding-right: 70px;
            padding-bottom: 15px;
            padding-left: 15px;
         }
         .chat-log > div .feedback[data-thanks="true"] > *:not(i):not(.thanks) {
            display: none;
         }
         .chat-log > div .feedback:not([data-thanks="true"]) .thanks {
            display: none;
         }

      }
      @media screen and (min-width: 1040px) {
         .chat-log > div .feedback {
            position: absolute;
            right: -50px;
         }
      }

   /* Editor */
      @media screen {
         
         .prompteneer {
            
         }
         .prompteneer .lists {
            
         }
         .chat-input-holder .open,
         .prompteneer .close {
            font-size: var(--fontscheme-logo-size);
            border-radius: 5px;
            cursor: pointer;
         }
         .prompteneer .editor {
            font-family: var(--fontscheme-code-family);
            font-size: var(--fontscheme-editor-size);
            outline: none;
            resize: none;
            white-space: nowrap; /* Firefox: pre-wrap */
         }
         .prompteneer .editor.wordwrap {
            white-space: normal; /* Tbc */
         }
         .prompteneer .controls {
            text-align: left;
            background-color: rgba(99,99,99,0.5);
         }
         .prompteneer .controls button {
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: var(--fontscheme-button-size);
            cursor: pointer;
         }
         .prompteneer .controls button:disabled {
            
         }

         .prompteneer .controls #wordwrap {
            display: inline-block;
            height: 18px;
            margin-right: 0px;
            margin-bottom: 3px;
            vertical-align: middle;
            width: 18px;
            cursor: pointer;
         }
         .prompteneer .controls div #wordwrap + label {
            cursor: pointer;
            font-style: italic;
            padding-left: 5px;
            color: transparent;
            cursor: default;
         }
         .prompteneer .controls:hover div #wordwrap + label {
            color: gray;
            cursor: pointer;
         }

      }

   /* Saver */
      @media screen {
         
         .promptSaving {
            
         }
         .promptSavingDialogue {
            border-radius: 10px;
         }
         .promptSavingOverlay {
            padding-right: 40px;
            padding-left: 40px;
         }
         .promptSavingOverlay table,
         .promptSavingOverlay tr,
         .promptSavingOverlay td {
            width: 100%;
            height: 100%;
         }
         .promptSavingHeader {
            border-bottom: transparent 1px solid;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor: default;
            font-size: var(--fontscheme-button-size);
            font-weight: normal;
         }
         .promptSaving h3 {
            cursor: default;
         }
         .promptSavingForm {
            
         }
         .promptSavingForm input[type="checkbox"] {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-top: 3px;
            margin-right: 10px;
            margin-bottom: 6px;
            vertical-align: middle;
            cursor: pointer;
         }
         .promptSavingForm input[type="checkbox"] + label {
            display: inline-block;
            cursor: pointer;
         }
         .promptSavingForm .section {
            font-weight: normal;
            padding-bottom: 15px;
            cursor: default;
         }
         .promptSavingFooter {
            border-top: transparent 1px solid;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
         }
         .promptSavingFooter button {
            cursor: pointer;
         }
         .promptSavingFooter :first-child.saving {
            background-color: var(--colorscheme-chat-front-bit);
         }

         #promptHidden > div {
            text-align: left;
            padding-top: 14px;
            padding-left: 0px;
         }
         #promptHidden > div:hover input {

         }

         #promptBy {
            display: inherit;
            font-style: italic;
         }

      }

   /* Entrance, loggedOut */
      @media screen {
         
         .entrance,
         .loggedOut {

         }
         .entrance  > div,
         .loggedOut > div {
            font-family: var(--fontscheme-normal-family);
         }
         .entrance  h1,
         .loggedOut h1 {
            font-size: var(--fontscheme-logo-size);
            cursor: default;
         }
         .entrance  h1 img,
         .loggedOut h1 img {
            width: calc(var(--fontscheme-logo-size) - 6px);
            height: calc(var(--fontscheme-logo-size) - 6px);
            border-radius: 2px;
            margin-right: 8px;
         /* background-image: var(--image-logo-file); */
         }
         .entrance  p,
         .loggedOut p {
            margin-top: 15px;
            margin-bottom: 15px;
            cursor: default;
         }
         .entrance  a,
         .loggedOut a {
            margin-left: 4px;
            text-decoration: underline;
         }
         .entrance  a:hover,
         .loggedOut a:hover {
            text-decoration: underline;
         }
         .entrance  input[type="checkbox"],
         .loggedOut input[type="checkbox"] {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
            vertical-align: middle;
            margin-bottom: 3px;
         }
         .entrance  label,
         .loggedOut label {
            cursor: pointer;
         }
         .entrance  button,
         .loggedOut button {
            border-radius: 5px;
            border-width: 1px;
            border-style: solid;
            cursor: pointer;
         }
         .entrance  button.disabled,
         .loggedOut button.disabled {
            cursor: wait;
         }

         .loggedOut p {
            line-height: 150%;
         }
         .loggedOut a {
            cursor: pointer;
         }

      }

   /* LoggedOut */
      @media screen {
         
      }

   /* Settings */
      @media screen {
         
         .settings, .trouble, .share {

         }
         .settings > div, .trouble > div, .share > div {
            font-family: var(--fontscheme-normal-family);
         }
         .settings h1, .trouble h1, .share h1 {
            font-size: var(--fontscheme-logo-size);
            cursor: default;
         }
         .settings h3, .trouble h3, .share h3 {
            font-weight: bold;
         }
         .settings h3, .trouble h3, .share h3,
         .settings h4, .trouble h4, .share h4,
         .settings i,  .trouble i,  .share i {
            margin-top: 40px;
            margin-bottom: 15px;
         }
         .settings h4, .trouble h4, .share h4 {
            margin-top: -10px;
            margin-bottom: 20px;
         }
         .settingsGroups > p {
            margin-bottom: 25px;
         }
         .settings h3, .trouble h3, .share h3,
         .settings h4, .trouble h4, .share h4,
         .settings p,  .trouble p,  .share p,
         .settings i,  .trouble i,  .share i {
            cursor: default;
         }
         .settings a, .trouble a, .share a {
            margin-left: 4px;
            text-decoration: underline;
         }
         .settings a:hover, .trouble a:hover, .share a:hover {
            text-decoration: underline;
         }
         .settings select, .trouble select, .share select {
            cursor: pointer;
         }
         .settings input[type="checkbox"],
         .trouble  input[type="checkbox"],
         .share    input[type="checkbox"] {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
            vertical-align: initial;
            margin-top: 6px;
         }
         .settings label, .trouble label, .share label {
            cursor: pointer;
            display: inline-block;
            max-width: calc(100% - 55px);
            overflow-x: auto;
            word-break: break-all;
            white-space: nowrap;
            text-overflow: ellipsis;
         }
         .settings button, .trouble button, .share button {
            border-radius: 5px;
            border-width: 1px;
            border-style: solid;
            cursor: pointer;
         }
         .settings button.disabled, .trouble button.disabled, .share button.disabled {
            cursor: wait;
         }

         .settings .userProfile li label {
            cursor: default;
            margin-bottom: -4px;
         }
         .settings .userProfile li span {
            cursor: text;
         }

         .settingsGroups    li.hr,
         .settingsFunctions li.hr,
         .settingsPeople    li.hr,
         .settingsAll       li.hr,
         .troubleGroup      li.hr,
         .shareGroup        li.hr {
            padding-top: 10px;
         }

         .settings .settingsRole select {
            margin-top: 0px;
         }

      }

   /* SignIn */
      @media screen {
         
         .signIn {
         }
         .signIn td > div {
            font-family: var(--fontscheme-normal-family);
         }
         .signIn.notready td > div h3,
         .signIn.notready td > div p,
         .signIn.notready td > div i,
         .signIn.notready td > div button {
            opacity: 0;
         }
         .signIn.ready td > div h3,
         .signIn.ready td > div p,
         .signIn.ready td > div i,
         .signIn.ready td > div button {
            opacity: 1;
         }
         .signIn h1 span {
            font-size: var(--fontscheme-logo-size);
            cursor: default;
         }
         .signIn h1 img {
            width: calc(var(--fontscheme-logo-size) - 6px);
            height: calc(var(--fontscheme-logo-size) - 6px);
            border-radius: 2px;
            margin-right: 8px;
         }
         .signIn h3 {
            font-weight: bold;
         }
         .signIn h3,
         .signIn i {
            margin-top: 15px;
            margin-bottom: 15px;
         }
         .signIn h3,
         .signIn p,
         .signIn i {
            cursor: default;
         }
         .signIn button {
            border-radius: 5px;
            border-width: 1px;
            border-style: solid;
            cursor: pointer;
         }
         .signIn button.disabled {
            cursor: wait;
         }

      }

   }

/* Colors */
   @media screen {

   /* General */
      @media screen {
         
      /* App */
         .App {
            background-color: var(--app-background);
            color: var(--app-font-color);
         }

      }

   /* Sidebar */
      @media screen {
         
      /* Sidebar */
         .sidebar {
            background-color: var(--sidebar-background);
            box-shadow: var(--sidebar-shadow);
         }
         .sidebar select,
         .sidebar input,
         .sidebar .reset {
            background-color: var(--sidebar-foreground);
            color: var(--sidebar-font-color);
            border-color: var(--sidebar-border-color);
         }
         .sidebar :hover > h3 {
            color: var(--colorscheme-sidebar-front-very);
         }
         .sidebar :hover > select,
         .sidebar :hover > input,
         .sidebar :hover > .reset {
            background-color: var(--sidebar-forehover);
            color: var(--colorscheme-sidebar-front-very);
         }
         .sidebar > div > .models *,
         .sidebar > div > .temperatures *,
         .sidebar > div > .tokens *,
         .sidebar > div > .role *,
         .sidebar > div > .functions,
         .sidebar > div > .functions *,
         .sidebar > div > .functions .reset *,
         .sidebar > div > .functions .reset {
            color: var(--sidebar-font-color-inactive);
         }

         .sidebar .role select:disabled {
            background-color: var(--sidebar-disground) !important;
         }
         .sidebar .role select:disabled:hover {
            background-color: var(--sidebar-dishover) !important;
         }
         [data-role="user"] .sidebar > div > .role {
            display: none !important;
         }

      /* Assistants */
         .sidebar > .assistants > div:not(.sidebar-header) {
            background-color: var(--sidebar-foreground);
            color: var(--sidebar-font-color-inactive);
            border-color: var(--sidebar-foreground);
         }
         .sidebar > .assistants > div:not(.sidebar-header):hover {
            color: var(--sidebar-font-color);
            border-color: var(--sidebar-forehover);
         }
         .sidebar > .assistants > div:not(.sidebar-header) strong {
            color: var(--sidebar-font-color);
         }
         .sidebar > .assistants > div:not(.sidebar-header).active {
            background-color: var(--sidebar-forehover);
            color: var(--sidebar-font-color);
            border-color: var(--sidebar-forehover);
         }
         .sidebar > .assistants > .functions.bottom:hover {
            background-color: var(--sidebar-forehover);
            color: var(--sidebar-font-color);
            border-color: var(--sidebar-forehover);
         }
      }

   /* Chat */
      @media screen {
         
         .chatbox {
            background-color: var(--chat-background);
         }
         .growWrap > textarea,
         .growWrap::after {
            background-color: var(--input-background);
            box-shadow: var(--input-shadow);
            color: var(--input-font-color);
         }

      }

   /* Message */
      @media screen {
         
         .chat-log > div {
            background-color: var(--message-background);
            border-color: transparent;
            border-bottom-color: var(--message-border);
            color: var(--message-font-color);
         }
         .chat-log > div.bot {
            background-color: var(--message-bot-background);
         }
         .chat-log > div.client {
            background-color: var(--message-client-background);
            color: var(--message-client-font-color);
         }
         .chat-log > div .avatar {
            background-color: var(--message-user-avatar-bg);
            color: var(--message-user-avatar-font);
         }
         .chat-log > div.bot .avatar {
            background-color: var(--message-bot-avatar-bg);
            color: var(--message-bot-avatar-font);
         }

         .chat-log > div .message code {
            background-color: var(--code-block-background);
            color: var(--code-inline-font-color);
         }
         .chat-log > div .message code:hover {
            background-color: var(--code-inline-background-hover);
            color: var(--code-inline-font-color-hover);
         }
         .chat-log > div .message pre > code {
            background-color: var(--code-block-background);
            color: var(--code-block-font-color);
         }
         .chat-log > div .message code.button {
            background-color: var(--code-action-background);
            color: var(--code-action-font-color);
         }
         .chat-log > div .message code.button:disabled {
            background-color: var(--code-action-background);
            color: var(--code-action-font-color-disabled);
            cursor: default;
         }
         .chat-log > div .message code.button:disabled:hover {
            background-color: var(--code-action-background);
            color: var(--code-action-font-color-disabled);
         }
         .chat-log > div .message code.button:hover {
            background-color: var(--code-action-background-hover);
            color: var(--code-action-font-color-hover);
         }
         .App[data-theme="statista"] .chat-log > div .message code.button:hover {
            background-color: var(--code-action-font-color-hover);
            color: var(--code-inline-font-color-hover);
         }
         .chat-log > div .message blockquote {
            border-left-color: var(--message-border);
         }
         .chat-log > div .message a {
            color: var(--message-link-color);
         }
         .chat-log > div .message a:hover {
            color: var(--message-link-hover);
         }
         .chat-log .init .message li a {
            color: var(--message-link-hover);
         }
         .chat-log .init .message li a:hover {
            color: var(--message-link-color);
         }
         .chat-log > div .message table {
            border-top-color: var(--message-border);
            border-left-color: var(--message-border);
         }
         .chat-log > div .message td,
         .chat-log > div .message th {
            border-right-color: var(--message-border);
            border-bottom-color: var(--message-border);
         }
         .chat-log > div .message th {
            background-color: var(--message-border);
         }

      }

   /* Feedback */
      @media screen {

         .chat-log > div .feedback:not([data-choice="null"]):hover,
         .chat-log > div .feedback[data-open="true"]:hover {
            box-shadow: var(--dialogue-shadow);
            background-color: var(--dialogue-background);
         }
         .chat-log > div .feedback i {
            color: var(--sidebar-link-hover);
         }
         .chat-log > div .feedback i,
         .chat-log > div .feedback .thanks {
            text-shadow: var(--message-fadeout);
         }
         .chat-log > div .feedback i.pos {
            
         }
         .chat-log > div .feedback i.neg {
            
         }
         .chat-log > div .feedback i:hover {
            color: var(--message-link-color);
         }
         .chat-log > div .feedback i[data-chosen='true'] {
            color: var(--message-font-color);
         }

      }

   /* Editor */
      @media screen {
         
         .prompteneer {
            background-color: var(--editor-background);
         }
         .prompteneer .lists {
            background-color: inherit;
            color: inherit;
         }
         .chat-input-holder .open {
            background-color: var(--open-background);
            color: var(--open-color);
         }
         .chat-input-holder .open:hover {
            background-color: var(--open-background-hover);
            color: var(--open-color-hover);
         }
         .prompteneer .close {
            background-color: var(--control-background);
            color: var(--control-font-color);
         }
         .prompteneer .close:hover {
            background-color: var(--control-background-hover);
            color: var(--control-font-color-hover);
         }
         .prompteneer .editor {
            background: var(--editor-background);
            color: var(--editor-font-color);
         }
         .prompteneer .controls {
            background: var(--editor-background-trans);
            color: var(--editor-font-color);
         }
         .prompteneer .close, 
         .prompteneer .controls button {
            background: var(--control-background);
            color: var(--control-font-color);
            box-shadow: var(--control-shadow);
         }
         .prompteneer .close:hover, 
         .prompteneer .controls button:hover {
            background-color: var(--control-background-hover);
         }
         .prompteneer .controls button:disabled {
            color: var(--control-font-color-disabled);
         }

      }

   /* Saver */
      @media screen {
         
         .promptSaving {
            background-color: var(--dialogue-backdrop);
         }
         .promptSavingDialogue {
            background-color: var(--dialogue-background);
            box-shadow: var(--dialogue-shadow);
            color: var(--dialogue-font-color);
         }
         .promptSavingOverlay {
            background-color: var(--colorscheme-sidebar-back-very);
            color: var(--colorscheme-sidebar-front-very);
            opacity: 0.8;
         }
         .promptSavingHeader {
            background-color: var(--colorscheme-grayen);
            color: var(--dialogue-chrome-font-color);
            border-bottom-color: var(--dialogue-background);
         }
         .promptSavingHeader div {
            color: var(--dialogue-chrome-font-color);
         }
         .promptSavingHeader div:hover {
            color: var(--dialogue-chrome-font-hover);
         }
         .promptSavingForm div input,
         .promptSavingForm div textarea,
         .promptSavingForm div select {
            background-color: var(--dialogue-chrome-background);
            color: var(--dialogue-font-color);
         }
         .promptSavingForm div:hover input,
         .promptSavingForm div:hover textarea,
         .promptSavingForm div:hover select {
            background-color: var(--dialogue-chrome-background-hover);
         }
         .promptSavingForm {
            color: var(--dialogue-font-color);
         }
         .promptSavingForm .section {
            color: var(--dialogue-chrome-font-color);
         }
         .promptSavingForm .notice {
            color: var(--dialogue-chrome-font-color);
         }
         .promptSavingFooter {
            background-color: var(--colorscheme-grayen);
            color: var(--dialogue-chrome-font-color);
            border-top-color: var(--dialogue-background);
         }
         .promptSavingFooter button {
            background: var(--control-background);
            color: var(--control-font-color);
            box-shadow: var(--control-shadow);
         }
         .promptSavingFooter button:hover {
            background-color: var(--control-background-hover);
         }
         
         .promptSavingForm input[type="checkbox"] + label {
            display: inline-block;
            cursor: pointer;
            color: var(--dialogue-chrome-font-color);
         }

         #promptBy {
            color: var(--dialogue-chrome-font-color);
         }


      }

   /* Help */
      @media screen {
            
         i[title],
         div:hover > h3 i[title],
         div:hover > h4 i[title] {
            background-color: var(--colorscheme-help-background);
            border-color: var(--colorscheme-help-border-color);
            color: var(--colorscheme-help-font-color);
         }

      }

   /* Entrance, loggedOut */
      @media screen {
         
         .App[data-theme="statista"] .entrance {
            background-color: var(--sidebar-background);
         }
         .loggedOutScreen {
            background-color: var(--sidebar-background);
            opacity: 0.9;
         }

         .App[data-theme="statista"] .entrance.inside,
         .App[data-theme="chatgpt"]  .entrance,
         .App[data-theme="statista"] .loggedOut.inside,
         .App[data-theme="chatgpt"]  .loggedOut {
            background-color: var(--chat-background);
         }
         .App[data-theme="statista"] .entrance  td > div *,
         .App[data-theme="statista"] .loggedOut td > div * {
            color: var(--sidebar-link-hover);
         }
         .App[data-theme="statista"] .entrance.inside  td > div *,
         .App[data-theme="statista"] .loggedOut.inside td > div * {
            color: var(--message-font-color);
         }
         .App[data-theme="chatgpt"]  .entrance  td > div *,
         .App[data-theme="chatgpt"]  .loggedOut td > div * {
            color: var(--colorscheme-dialogue-front-some);
         }
         .App[data-theme="statista"] .entrance  h1 span,
         .App[data-theme="statista"] .loggedOut h1 span {
            color: var(--sidebar-font-color) !important;
         }
         .App[data-theme="statista"] .entrance.inside h1 span,
         .App[data-theme="chatgpt"]  .entrance h1 span,
         .App[data-theme="statista"] .loggedOut.inside h1 span,
         .App[data-theme="chatgpt"]  .loggedOut h1 span {
            color: var(--colorscheme-dialogue-front-very) !important;
         }
         .App[data-theme="statista"] .entrance h1 + p,
         .App[data-theme="statista"] .entrance label,
         .App[data-theme="statista"] .loggedOut h1 + p,
         .App[data-theme="statista"] .loggedOut label {
            color: var(--sidebar-font-color) !important;
         }
         .App[data-theme="statista"] .entrance.inside h1 + p,
         .App[data-theme="statista"] .entrance.inside label,
         .App[data-theme="chatgpt"]  .entrance h1 + p,
         .App[data-theme="chatgpt"]  .entrance label,
         .App[data-theme="statista"] .loggedOut.inside h1 + p,
         .App[data-theme="statista"] .loggedOut.inside label,
         .App[data-theme="chatgpt"]  .loggedOut h1 + p,
         .App[data-theme="chatgpt"]  .loggedOut label {
            color: var(--colorscheme-dialogue-front-very) !important;
         }
         .App[data-theme="statista"] .entrance  a {
            color: var(--sidebar-font-color) !important;
         }
         .App[data-theme="statista"] .entrance.inside a,
         .App[data-theme="chatgpt"]  .entrance a {
            color: var(--colorscheme-dialogue-front-very) !important;
         }
         .App[data-theme="statista"] .entrance  a:hover {
            color: var(--sidebar-link-color) !important;
         }
         .App[data-theme="statista"] .entrance.inside a:hover
         .App[data-theme="chatgpt"]  .entrance a:hover {
            color: var(--colorscheme-dialogue-front-some) !important;
         }
         .loggedOut a {
            color: var(--sidebar-font-color-inactive) !important;
         }
         .loggedOut a:hover {
            color: var(--sidebar-font-color) !important;
         }

         .entrance  input[type="checkbox"],
         .loggedOut input[type="checkbox"] {
            cursor: pointer;
         }
         .App[data-theme="statista"] .entrance  button.disabled,
         .App[data-theme="statista"] .loggedOut button.disabled {
            background-color: var(--colorscheme-dialogue-front-very) !important;
            color: var(--colorscheme-dialogue-front-some);
         }
         .App[data-theme="statista"] .entrance  button.disabled:hover,
         .App[data-theme="statista"] .loggedOut button.disabled:hover {
            color: var(--sidebar-font-hover);
         }
         .App[data-theme="statista"] .entrance  td > div button,
         .App[data-theme="statista"] .loggedOut td > div button {
            background-color: var(--colorscheme-dialogue-front-very);
            color: var(--sidebar-font-color);
            border: var(--colorscheme-sidebar-border);
         }
         .App[data-theme="statista"] .entrance  button:hover
         .App[data-theme="statista"] .loggedOut button:hover {
            background-color: var(--sidebar-font-color);
            color: var(--colorscheme-dialogue-front-very);
         }

         .App[data-theme="statista"] .entrance.inside button:disabled,
         .App[data-theme="chatgpt"]  .entrance button.disabled,
         .App[data-theme="statista"] .loggedOut.inside button:disabled,
         .App[data-theme="chatgpt"]  .loggedOut button.disabled {
            color: var(--colorscheme-dialogue-front-some) !important;
         }
         .App[data-theme="statista"] .entrance.inside  button,
         .App[data-theme="statista"] .loggedOut.inside button {
            background-color: var(--colorscheme-dialogue-front-very);
            color: var(--colorscheme-dialogue-back-very) !important;
            border: var(--colorscheme-dialogue-front-very);
         }
         .App[data-theme="statista"] .entrance.inside button:hover,
         .App[data-theme="statista"] .loggedOut.inside button:hover {
            background-color: var(--colorscheme-dialogue-front-some);
         }

         .App[data-theme="chatgpt"]  .entrance  button,
         .App[data-theme="chatgpt"]  .loggedOut button {
            background-color: var(--colorscheme-dialogue-back-very);
            color: var(--colorscheme-dialogue-front-very) !important;
            border: var(--colorscheme-sidebar-border);
         }
         .App[data-theme="chatgpt"]  .loggedOut button {
            background-color: var(--colorscheme-dialogue-back-some);
         }
         .App[data-theme="chatgpt"]  .entrance  button:hover {
            background-color: var(--colorscheme-dialogue-back-some);
         }
         .App[data-theme="chatgpt"]  .loggedOut button:hover {
            background-color: var(--colorscheme-dialogue-front-very);
         }

         .entrance  .regulations {
            color: var(--app-link-hover);
         }

      }

   /* Settings */
      @media screen {
         
         .settings, .trouble, .share {
            background-color: var(--chat-background);
         }
         .settings td > div *, 
         .trouble  td > div *, 
         .share    td > div * {
            color: var(--colorscheme-dialogue-front-some);
         }
         .settings h1, .trouble h1, .share h1 {
            color: var(--colorscheme-dialogue-front-very) !important;
         }
         .App[data-theme="chatgpt"] .settings h3, .App[data-theme="chatgpt"] .trouble h3, .App[data-theme="chatgpt"] .share h3,
         .App[data-theme="chatgpt"] .settings h4, .App[data-theme="chatgpt"] .trouble h4, .App[data-theme="chatgpt"] .share h4 {
            color: var(--colorscheme-dialogue-front-very);
         }
         .App[data-theme="statista"] .settings h3, .App[data-theme="statista"] .trouble h3, .App[data-theme="statista"] .share h3,
         .App[data-theme="statista"] .settings h4, .App[data-theme="statista"] .trouble h4, .App[data-theme="statista"] .share h4 {
            color: var(--colorscheme-dialogue-front-some);
         }
         .App[data-theme="statista"] .settings div:hover > h3, 
         .App[data-theme="chatgpt"]  .trouble  div:hover > h3, 
         .App[data-theme="chatgpt"]  .share    div:hover > h3,
         .App[data-theme="statista"] .settings div:hover > h4, 
         .App[data-theme="chatgpt"]  .trouble  div:hover > h4, 
         .App[data-theme="chatgpt"]  .share    div:hover > h4 {
            color: var(--colorscheme-dialogue-front-very);
         }
         .settings h1 + p, .trouble h1 + p, .share h1 + p,
         .settings label,  .trouble label,  .share label {
            color: var(--colorscheme-dialogue-front-very);
         }
         .settings a, .trouble a, .share a {
            color: var(--colorscheme-dialogue-front-very) !important;
         }
         .settings a:hover, .trouble a:hover, .share a:hover {
            color: var(--colorscheme-dialogue-front-some) !important;
         }
         .settings div > select,    .trouble div > select,    .share div select,
         .settings div > input,     .trouble div > input,     .share div input,
         .settings div > textarea,  .trouble div > textarea,  .share div textarea {
            background-color: var(--sidebar-foreground);
            color: var(--sidebar-font-color);
            border-color: var(--sidebar-border-color);
         }
         .settings div > select.invalid, .trouble div > select.invalid, .share div select.invalid,
         .settings div > input.invalid,  .trouble div > input.invalid,  .share div input.invalid {
            border-color: red;
            border-width: 3px;
            padding: 7px;
         }
         .settings div:hover > h3, .trouble div:hover > select,
         .settings div:hover > h4, .trouble div:hover > select {
            color: var(--colorscheme-sidebar-front-very);
         }
         .settings div:hover > select, .trouble div:hover > select,
         .settings div:hover > input,  .trouble div:hover > input {
            background-color: var(--sidebar-forehover);
            color: var(--colorscheme-sidebar-front-very);
         }
         .settings input[type="checkbox"], 
         .trouble  input[type="checkbox"], 
         .share    input[type="checkbox"] {
            cursor: pointer;
         }
         .App[data-theme="statista"] .settings button.disabled, 
         .App[data-theme="statista"] .trouble  button.disabled, 
         .App[data-theme="statista"] .share    button.disabled {
            color: var(--colorscheme-dialogue-front-very) !important;
         }
         .App[data-theme="statista"] .settings button.disabled:hover, 
         .App[data-theme="statista"] .trouble  button.disabled:hover, 
         .App[data-theme="statista"] .share    button.disabled:hover {
            color: var(--colorscheme-dialogue-front-some) !important;
         }
         .App[data-theme="statista"] .settings button, 
         .App[data-theme="statista"] .trouble  button, 
         .App[data-theme="statista"] .share    button {
            background-color: var(--colorscheme-dialogue-front-some);
            color: var(--colorscheme-dialogue-back-very) !important;
            border: var(--colorscheme-sidebar-border);
         }
         .App[data-theme="statista"] .settings button:hover, 
         .App[data-theme="statista"] .trouble  button:hover, 
         .App[data-theme="statista"] .share    button:hover {
            background-color: var(--control-background-hover);
         }

         .App[data-theme="chatgpt"] .settings button.disabled, 
         .App[data-theme="chatgpt"] .trouble  button.disabled, 
         .App[data-theme="chatgpt"] .share    button.disabled {
            color: var(--colorscheme-dialogue-front-some) !important;
         }
         .App[data-theme="chatgpt"] .settings button, 
         .App[data-theme="chatgpt"] .trouble  button, 
         .App[data-theme="chatgpt"] .share    button {
            background-color: var(--colorscheme-dialogue-back-very);
            color: var(--colorscheme-dialogue-front-very) !important;
            border: var(--colorscheme-sidebar-border);
         }
         .App[data-theme="chatgpt"] .settings button:hover, 
         .App[data-theme="chatgpt"] .trouble  button:hover, 
         .App[data-theme="chatgpt"] .share    button:hover {
            background-color: var(--colorscheme-dialogue-back-some);
         }

         .settings td > div > div:hover .userProfile li span, 
         .trouble  td > div > div:hover .userProfile li span, 
         .share    td > div > div:hover .userProfile li span {
            color: var(--colorscheme-dialogue-front-very);
         }

         .settingsGroups > div:hover          [data-active="true"]  + label, .troubleGroup:hover    [data-active="true"]  + label, .shareGroup:hover    [data-active="true"]  + label,
         .settingsGroups > div:hover li:hover [data-active="false"] + label, .troubleGroup li:hover [data-active="false"] + label, .shareGroup li:hover [data-active="false"] + label {
            color: var(--colorscheme-dialogue-front-very);
         }

         .share textarea {
            height: 200px;
         }

         .shareCode {
            position: fixed;
            right: 0px;
            bottom: 0px;
            background-color: var(--colorscheme-chat-back-some);
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 0px 0px;
            margin-bottom: 0px !important;
            max-width: none !important;
         }
         .App[data-view="widget"] .shareCode {
            left: 0px;
            width: 100%;
         }
         .App:not([data-view="widget"]) .shareCode {
            left: 300px;
            width: calc(100% - 300px) !important;
         }
         
         .shareCode h3 {
            margin-top: 30px;
         }
         .shareCode textarea {
            padding: 15px;
            font-family: monospace;
         }
         .shareCode input[id^="none"] {
            color: var(--colorscheme-chat-front-bit);
         }
         .share td > div {
            margin-bottom: 350px !important;
         }
         .sharePrompt select {
            position: absolute;
            margin-left: 269px !important;
            width: 18px !important;
         }

      }

   /* SignIn */
      @media screen {
         
         .signIn {
            background-color: var(--sidebar-background);
         }
         .signIn td > div * {
         /* color: var(--colorscheme-dialogue-front-some); */
         }
         .signIn h1 span,
         .signIn h3 {
            color: var(--colorscheme-sidebar-front-very) !important;
         }
         .App[data-theme="statista"] .signIn h1 + p {
            color: var(--colorscheme-sidebar-front-some) !important;
         }
         .App[data-theme="chatgpt"] .signIn h1 + p {
            color: var(--colorscheme-sidebar-front-very) !important;
         }
         .signIn div:hover > h3 {
            color: var(--colorscheme-sidebar-front-very);
         }
         .App[data-theme="statista"] .signIn button {
            background-color: var(--sidebar-link-color);
            color: var(--sidebar-font-color) !important;
            border: var(--colorscheme-sidebar-border);
         }
         .App[data-theme="statista"] .signIn button:hover {
            background-color: var(--sidebar-font-color);
            color: var(--sidebar-link-color) !important;
            border: var(--sidebar-font-color);
         }
         .App[data-theme="chatgpt"] .signIn button {
            background-color: var(--colorscheme-sidebar-back-some);
            border: var(--colorscheme-sidebar-border);
            color: var(--colorscheme-sidebar-front-very)!important;
         }
         .App[data-theme="chatgpt"] .signIn button:hover {
            background-color: var(--colorscheme-sidebar-back-bit);
         }

      }

   }
